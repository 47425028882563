import {Button, Card, Flex, Tabs, DatePicker} from "antd";
import {useEffect, useState} from "react";
import {requestFn} from "../helpers";
import {Link} from "react-router-dom";
import {
    BarChart,
    Bar, XAxis,
    YAxis, CartesianGrid,
    Tooltip, Legend,
    ResponsiveContainer
} from 'recharts';
import {
    simplifyData,
    transformByBatchNumber,
    transformByOwnerAlias,
    trasformedByStepName,
    trasformedByStepNameAndAffector,
} from "../statTransformers";

const {RangePicker} = DatePicker;

function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
}


function formatDateWithoutYear(timestamp) {
    const date = new Date(timestamp);
    const month = date.toLocaleString('cs-CS');
    return month.replace(/202\d/g, '').split(':').slice(0, -1).join(':');
}


const average = (arr) => Math.round(arr.reduce((a, b) => a + b, 0) / arr.length);

const CustomizedTick = (props) => {
    const {x, y, payload} = props;
    return (
      <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#fff" transform="rotate(-35)">
              {payload.value}
          </text>
      </g>
    );
}

export const Stats = ({token}) => {
    const [scope, setScope] = useState(30)
    const [affector, setAffector] = useState('batchNumber')
    const [date, setDate] = useState(null)
    const [error, setError] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const getData = async () => {
        !loading && setLoading(true)
        let path = `/events?type=test`
        let requestScope = scope ? `&limit=${scope}` : ''
        let requestDate = date ? `&from=${new Date(date[0]).toISOString()}&to=${new Date(date[1]).toISOString()}` : ''
        const finalPath = `${path}${requestScope}${requestDate}`
        await requestFn('GET', finalPath, token, setData, setError)
        setLoading(false)
    }

    const tabList = [
        {
            key: 30,
            label: '30',
        },
        {
            key: 60,
            label: '60',
        },
        {
            key: 100,
            label: '100',
        },
        {
            key: 250,
            label: '250',
        },
    ]
    const affectorTabList = [
        {
            key: 'batchNumber',
            label: 'By batch #',
        },
        {
            key: 'ownerAlias',
            label: 'By barel',
        },
    ]

    useEffect(() => {
        getData()
    }, [scope, date]);

    const testWithTime = data.filter((item) => item.updateData?.newTestData?.additionalData?.testTime)
    const testTimes = testWithTime.map((item) => item.updateData.newTestData.additionalData.testTime);

    // console.log(data)
    const simplifiedData = simplifyData(data)

    /*
[
    {
        "test_name": "FIRMWARE & SUID",
        "test_command": "FW_TEST",
        "test_description": "Check if firmware is up to date and set SUID",
        "test_status": "SUCCESS",
        "test_version": 1,
        "test_steps": [
            {
                "test_step_name": "Firmware update",
                "test_step_description": "Check if firmware is up to date and update if needed",
                "test_step_command": "FW_UPDATE",
                "test_step_retries": 0,
                "test_step_timetout_seconds": 200,
                "test_step_status": "SUCCESS",
                "test_step_value": "FW update: not_needed0.51"
            },
            {
                "test_step_name": "SUID & HW",
                "test_step_description": "Set SUID and HW version",
                "test_step_command": "GET_SET_SUID",
                "test_step_retries": 0,
                "test_step_timetout_seconds": 30,
                "test_step_status": "FAIL",
                "test_step_value": "SUID SASLE0M & hw version 5.0 set"
            }
        ]
    },
     */

    // console.log(simplifiedData)
    const byStepName = trasformedByStepName(simplifiedData)

    const byStepNameAndOwner = trasformedByStepNameAndAffector(simplifiedData, 'ownerAlias')
    const byStepNameAndBatch = trasformedByStepNameAndAffector(simplifiedData, 'batchNumber')

    const decideData = () => {
        if (affector === 'batchNumber') {
            return byStepNameAndBatch
        }
        if (affector === 'ownerAlias') {
            return byStepNameAndOwner
        }
    }

    const barData = decideData().map((item) => {
        return Object.entries(item).map(([key, value]) => ({
                dataKey: key,
                stackId: key.split('_')[0]
        }))
    }).flat()
    const uniqueBarData = barData.filter((item, index, self) => {
          return !['undefined', 'label'].includes(item.stackId) && index === self.findIndex((t) => (
            t.dataKey === item.dataKey && t.stackId === item.stackId
          ));
      }
    )
    const chartData = decideData() || []

    const byBatchNumber = transformByBatchNumber(simplifiedData)

    const byOwnerAlias = transformByOwnerAlias(simplifiedData)

    const simplifiedDataWithoutTests = simplifiedData.map((item) => {
        let newData = {...item};
        delete newData.testsData;
        return newData;
    })
    return (
        <div style={{
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}>
            <h2>{`Test time average in ${date ? 'time range' : ''} ${scope ? 'last ' +  scope + "devices" : ''}: ${loading && !data.length ? 'Loading...' : formatTime(average(testTimes))}`}</h2>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <Tabs
                      style={{width: 300}}
                      activeKey={scope}
                      onChange={setScope}
                      items={tabList}
                    />
                    <RangePicker
                      showTime
                      onChange={(date) => {
                          setScope(0)
                          setDate(date);

                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      size='small'
                    />
                </div>
                <Tabs
                  style={{width: 300}}
                  activeKey={affector}
                  onChange={setAffector}
                  items={affectorTabList}
                />
                <Button loading={loading} onClick={getData}>REFETCH</Button>
            </div>
            <div style={{minHeight: 500, display: "flex"}}>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={chartData}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 80,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-75}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        {uniqueBarData.map((item) => (
                          <Bar
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            stackId={item.stackId}
                            fill={item.dataKey.includes('SUCCESS') ? "#82ca9d" : "#ec7063"}
                          />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div style={{minHeight: 500, display: "flex"}}>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={byStepName}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 80,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-75}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        <Bar dataKey="SUCCESS" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="FAILED" stackId="a" fill="#ec7063" />
                        <Bar dataKey="NOT_STARTED" stackId="a" fill="gray" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div style={{minHeight: 500, display: "flex"}}>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={byOwnerAlias}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 60,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-50}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        <Bar dataKey="SUCCESS" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="FAILED" stackId="a" fill="#ec7063" />
                    </BarChart>
                </ResponsiveContainer>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={byBatchNumber}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 60,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-50}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        <Bar dataKey="SUCCESS" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="FAILED" stackId="a" fill="#ec7063" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Card style={{ width: '100%' }}>
                <Flex vertical>
                    <div>
                        <table>
                            <thead>
                            <tr>
                                {Object.keys(simplifiedDataWithoutTests[0] || {}).map((item) => (
                                  <th key={item}>{item}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {simplifiedDataWithoutTests.map((item) => (
                              <tr key={item.timestamp}>
                                  <td>{formatDateWithoutYear(item.timestamp)}</td>
                                  <td>{item.ownerAlias}</td>
                                  <td><Link to={`/device/${item?.suid}`}>{item?.suid}</Link></td>
                                  <td>{item.result}</td>
                                  <td>{item.batchNumber}</td>
                                  <td>{item.testTime && formatTime(item.testTime)}</td>
                              </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </Flex>
            </Card>
        </div>
    )
}
